<template>
    <div class="summary">
        <overview-filters
            :filter-confidence="true"
            :is-loading="isLoading"
        />
        <div class="app-container">
            <be-page-header class="mb-3" :app-container="false">
                <template v-slot:default>
                    Health & Safety Overview
                </template>
                <template v-slot:subheading>
                    <be-detections-heading />
                </template>
            </be-page-header>
            <div class="summary__cols columns is-multiline">
                <div class="column is-12">
                    <div class="summary__contribute">
                        <be-contribute-images />
                    </div>
                </div>
                <div class="column is-12">
                    <system-totals :can-load-data="true" />
                </div>
                <div class="summary__half column is-12">
                    <safety-events-by-camera :can-load-data="true" />
                </div>
                <!-- <div class="summary__half column is-12">
                    <speed-chart :can-load-data="true" />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import BeContributeImages from '@/components/global/BeContributeImages';
import OverviewFilters from '@/components/global/OverviewFilters';
import SystemTotals from '@/components/widgets/hs/SystemTotals';
import BePageHeader from '@/components/global/BePageHeader';
// import SpeedChart from '@/components/widgets/charts/SpeedChart';
import SafetyEventsByCamera from '@/components/widgets/hs/charts/SafetyEventsByCamera';

export default {
    name: 'HealthSafetySummary',

    components: {
        BeDetectionsHeading,
        BeContributeImages,
        BePageHeader,
        OverviewFilters,
        SystemTotals,
        // SpeedChart,
        SafetyEventsByCamera,
    },

    computed: {
        ...mapGetters({
            isLoadingSummary: 'hs/summary/isLoading',
            isLoadingProximity: 'hs/proximity/isLoading',
            isLoadingDetections: 'explorer/detections/isLoading',
            isLoadingDailySummary: 'explorer/detections/unique/dailysummary/isLoading',
        }),

        isLoading() {
            return this.isLoadingSummary
                || this.isLoadingProximity
                || this.isLoadingDetections
                || this.isLoadingDailySummary;
        },
    },

    methods: {
        ...mapActions('hs/summary', ['loadData']),
    },

    mounted() {
        this.loadData();
    },
};
</script>

<style lang="scss" scoped>
.summary {
    &__contribute {
        display: flex;
        justify-content: flex-end;
    }

    &__half {
        display: flex;
        justify-content: stretch;
    }
}
</style>
