<template>
    <div class="totals columns is-multiline">
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-critical" :is-loading="isLoading">
                <template v-slot:heading>Elev. Risk Observations (High Speed)</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ riskLevelCount.veryHigh }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-danger" :is-loading="isLoading">
                <template v-slot:heading>Elevated Risk Observations</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ riskLevelCount.high }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-warning" :is-loading="isLoading">
                <template v-slot:heading>Moderate Risk Observations</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ riskLevelCount.medium }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-success" :is-loading="isLoading">
                <template v-slot:heading> Low Risk Observations</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ riskLevelCount.low }}</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    watch: {
        getFilterHash() {
            this.refreshData();
        },
    },

    mounted() {
        this.refreshData();
    },

    computed: {
        ...mapGetters('hs/riskLevel', ['getCountData', 'isLoading']),
        ...mapGetters('dataFilters', ['getFilterHash']),

        riskLevelCount() {
            return this.getCountData;
        },
    },

    methods: {
        ...mapActions('hs/riskLevel', ['loadData']),

        refreshData() {
            this.loadData();
        },
    },
};
</script>

<style lang="scss" scoped>
.totals {
    justify-content: stretch;

    &__col {
        display: flex;
        justify-content: stretch;

        ::v-deep .be-widget__content {
            display: flex;
            align-items: end;
        }
    }
}
</style>
