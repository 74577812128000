<template>
    <div
        class="parking-management control"
        :class="[size, { 'is-expanded': expanded }]"
    >
        <b-dropdown
            ref="dropdown"
            :position="position"
            :disabled="disabled"
            :mobile-modal="true"
            :trap-focus="true"
            :expanded="expanded"
            :append-to-body="appendToBody"
            append-to-body-copy-parent
            @active-change="onActiveChange"
        >
            <template v-slot:trigger>
                <b-input
                    ref="input"
                    autocomplete="off"
                    :value="formattedValue"
                    :placeholder="placeholder"
                    :size="size"
                    :icon="icon"
                    :icon-pack="iconPack"
                    :rounded="rounded"
                    :loading="loading"
                    :disabled="disabled"
                    :readonly="!editable"
                    v-bind="$attrs"
                    :use-html5-validation="false"
                    @click.native="onInputClick"
                    @keyup.native.enter="togglePicker(true)"
                    @focus="handleOnFocus"
                />
            </template>
            <template v-slot:default>
                <b-dropdown-item
                    :disabled="disabled"
                    :focusable="focusable"
                    custom
                >
                    <b-field grouped position="is-centered">
                        <b-field label="Days" custom-class="is-small">
                            <b-select
                                v-model="focusedData.days"
                                :disabled="disabled"
                                placeholder="00"
                            >
                                <option
                                    v-for="day in days"
                                    :value="day.value"
                                    :key="day.value"
                                >
                                    {{ day.label }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Hours" custom-class="is-small">
                            <b-select
                                v-model="focusedData.hours"
                                :disabled="disabled"
                                placeholder="00"
                            >
                                <option
                                    v-for="hour in hours"
                                    :value="hour.value"
                                    :key="hour.value"
                                >
                                    {{ hour.label }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Minutes" custom-class="is-small">
                            <b-select
                                v-model="focusedData.minutes"
                                :disabled="disabled"
                                placeholder="00"
                            >
                                <option
                                    v-for="minute in minutes"
                                    :value="minute.value"
                                    :key="minute.value"
                                >
                                    {{ minute.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-field>
                </b-dropdown-item>
                <b-dropdown-item v-if="hasConfirm" custom :focusable="focusable">
                    <div class="dwell-time__confirm">
                        <button type="button" class="be-button-link is-smaller is-danger" @click.prevent="cancel">Cancel</button>
                        <b-button class="be-button is-wider" type="is-primary" size="is-small" @click.prevent="set">
                            Set
                        </b-button>
                    </div>
                </b-dropdown-item>
            </template>
        </b-dropdown>
    </div>
</template>

<script>
import FormElementMixin from 'buefy/src/utils/FormElementMixin';
import {
    convertDwellTimeObjectToMinutes,
    convertDwellTimeToObject,
    formatDwellTime,
    formatNumber,
} from '@/utils/dwell';

export default {
    mixins: [FormElementMixin],

    inheritAttrs: false,

    props: {
        // value in seconds
        value: {
            type: [String, Number],
            default: 0,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        editable: Boolean,
        disabled: Boolean,
        position: {
            type: String,
            default: '',
        },
        closeOnClick: {
            type: Boolean,
            default: false,
        },
        focusable: {
            type: Boolean,
            default: false,
        },
        appendToBody: Boolean,
        incrementDays: {
            type: Number,
            default: 1,
        },
        maxDays: {
            type: Number,
            default: 90,
        },
        incrementHours: {
            type: Number,
            default: 1,
        },
        incrementMinutes: {
            type: Number,
            default: 5,
        },
        hasConfirm: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            currentValue: this.value,
            focusedData: this.convertToObject(this.value),
            /* eslint-disable-next-line vue/no-reserved-keys */
            _elementRef: 'input',
            /* eslint-disable-next-line vue/no-reserved-keys */
            _isDwellTime: true,
        };
    },

    computed: {
        computedValue: {
            get() {
                return this.currentValue;
            },
            set(value) {
                this.updateCurrentValue(value);
                this.$emit('input', value);
            },
        },

        formattedValue() {
            return formatDwellTime({ time: this.computedValue });
        },

        days() {
            const days = [];
            for (let i = 0; i <= this.maxDays; i += this.incrementDays) {
                days.push({
                    label: formatNumber(i, true),
                    value: i,
                });
            }
            return days;
        },

        hours() {
            const hours = [];
            for (let i = 0; i < 24; i += this.incrementHours) {
                hours.push({
                    label: formatNumber(i, true),
                    value: i,
                });
            }
            return hours;
        },

        minutes() {
            const minutes = [];
            for (let i = 0; i < 60; i += this.incrementMinutes) {
                minutes.push({
                    label: formatNumber(i, true),
                    value: i,
                });
            }
            return minutes;
        },
    },

    watch: {
        value(value) {
            this.updateFocusedData(value);
            this.set(false);
            this.togglePicker(false);
        },

        focusedData: {
            handler() {
                if (!this.hasConfirm) {
                    this.set(false);
                }
            },
            deep: true,
        },
    },

    methods: {
        cancel() {
            this.toggle();
        },

        set(toggle = true) {
            this.computedValue = convertDwellTimeObjectToMinutes({
                dwell: this.focusedData,
            });
            if (toggle) {
                this.toggle();
            }
        },

        convertToObject(time) {
            return convertDwellTimeToObject({ time });
        },

        updateCurrentValue(value) {
            this.currentValue = value;
        },

        updateFocusedData(value) {
            this.focusedData = this.convertToObject(value);
        },

        /*
         * Toggle datepicker
         */
        togglePicker(active) {
            if (this.$refs.dropdown) {
                if (this.closeOnClick) {
                    this.$refs.dropdown.isActive = typeof active === 'boolean'
                        ? active
                        : !this.$refs.dropdown.isActive;
                }
            }
        },

        /*
         * Call default onFocus method and show datepicker
         */
        handleOnFocus(event) {
            this.onFocus(event);
            this.togglePicker(true);
        },

        /*
         * Toggle dropdown
         */
        toggle() {
            this.$refs.dropdown.toggle();
        },

        /*
         * Avoid dropdown toggle when is already visible
         */
        onInputClick(event) {
            if (this.$refs.dropdown.isActive) {
                event.stopPropagation();
            }
        },

        onActiveChange(value) {
            if (!value) {
                this.onBlur();
            }
        },
    },

    created() {
        if (typeof window !== 'undefined') {
            document.addEventListener('keyup', this.keyPress);
        }
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            document.removeEventListener('keyup', this.keyPress);
        }
    },
};
</script>

<style lang="scss" scoped>
.parking-management {
    &__confirm {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ::v-deep .dropdown-menu {
        z-index: map-get($z-index, nav);
    }

    ::v-deep input {
        cursor: pointer;
        //width: 100px;
    }

    @include tablet {
        ::v-deep .dropdown.is-expanded.is-mobile-modal .dropdown-menu,
        ::v-deep .dropdown-menu {
            width: auto;
            max-width: none;
        }
    }
}

.dwell-time__confirm {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10px;
}

</style>
