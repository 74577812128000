import dayjs from 'dayjs';
import { isEmpty, isString } from 'lodash';
import { datetimeApiFormatter, datetimeAsUtcApiFormatter } from '@/utils/formatters/datetime';
import { DEFAULT_PAGINATION_PER_PAGE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import timezoneConfig from '@/config/timezone.config';

export const getDefaultDataState = ({ hasPagination = false }) => {
    const state = {
        data: null,
        status: '',
        dataHash: null,
    };

    if (hasPagination) {
        state.currentPage = 1;
        state.perPage = DEFAULT_PAGINATION_PER_PAGE;
    }

    return state;
};

// takes time input and converts it to api timezone then formats for the api
export const buildStartEndDateUrl = ({ path, startDate = null, endDate = null }) => {
    const apiStartDate = dayjs(startDate).tz(timezoneConfig.apiTz);
    const apiEndDate = dayjs(endDate).tz(timezoneConfig.apiTz);

    if (startDate && endDate) {
        return `${path}/${datetimeApiFormatter(apiStartDate)}/to/${datetimeApiFormatter(apiEndDate)}`;
    }
    return path;
};

export const buildStartEndDateAsUtcUrl = ({ path, startDate = null, endDate = null }) => {
    if (startDate && endDate) {
        return `${path}/${datetimeAsUtcApiFormatter(startDate)}/to/${datetimeAsUtcApiFormatter(endDate)}`;
    }
    return path;
};

export const buildUrlParams = ({ tags = [], plate = [], confidence = '', risk = '', page = null, perPage = null, additional = {} }) => {
    let params = {};
    if (tags.length) {
        params.tags = tags.map((i) => i.trim()).join(',');
    }

    if (plate.length) {
        params.plate = plate.map((i) => i.trim()).join(',');
    }

    if (isString(confidence) && confidence && confidence.trim() !== 'all') {
        params.confidence = confidence.trim();
    }

    if (isString(risk) && risk && risk.trim() !== 'all') {
        params.risk = risk.trim();
    }

    if (page) {
        params.page = page;
    }

    if (perPage) {
        params.page_length = perPage;
    }

    if (Object.values(additional).length) {
        params = {
            ...params,
            ...additional,
        };
    }

    return params;
};

export const getDefaultDataMutations = ({ hasPagination = false }) => {
    const mutations = {
        setData(state, { data }) {
            state.data = data;
        },
        setStatus(state, { status }) {
            state.status = status;
        },
        setDataHash(state, { dataHash }) {
            state.dataHash = dataHash;
        },
        resetState(state) {
            Object.assign(state, getDefaultDataState({ hasPagination }));
        },
    };

    if (hasPagination) {
        mutations.setPage = function (state, { page }) {
            state.currentPage = page;
        };
        mutations.setPerPage = function (state, { perPage }) {
            state.perPage = perPage;
        };
    }

    return mutations;
};

export const getDefaultDataGetters = ({ hasPagination = false }) => {
    const getters = {
        isLoading: (state) => state.status === STATUS_LOADING,
        isSuccess: (state) => state.status === STATUS_SUCCESS,
        hasData: (state) => !isEmpty(state.data),
    };

    if (hasPagination) {
        getters.prevPage = function (state) {
            return state.data
                ? state.data.previous
                : null;
        };

        getters.nextPage = function (state) {
            return state.data
                ? state.data.next
                : null;
        };

        getters.overallCount = function (state) {
            return state.data
                ? (state.data.count || 0)
                : 0;
        };

        getters.apiResults = function (state) {
            return state.data
            && state.data.results
                ? state.data.results
                : [];
        };
    }

    return getters;
};

export function createDataHash(...args) {
    const dataHash = JSON.stringify([...args]);
    return dataHash;
}

export function getStatusGetters() {
    return {
        isLoading: (state) => state.status === STATUS_LOADING,
        isSuccess: (state) => state.status === STATUS_SUCCESS,
        isError: (state) => state.status === STATUS_ERROR,
    };
}
