import { mapGetters } from 'vuex';

export default {
    props: {
        canLoadData: {
            type: Boolean,
            default: false,
        },
        loadDataPage: {
            type: Number,
            default: 1,
        },
        loadDataPerPage: {
            type: Number,
            default: null,
        },
    },

    computed: {
        ...mapGetters('dataFilters', ['getFilterHash']),
    },

    watch: {
        perPage() {
            this.$emit('update');
            if (this.canLoadData) {
                this.loadData({});
            }
        },

        currentPage() {
            this.$emit('update');
            if (this.canLoadData) {
                this.loadData({});
            }
        },

        getFilterHash() {
            this.$emit('update');
            if (this.canLoadData) {
                this.loadData({ page: 1 });
            }
        },

        canLoadData() {
            this.loadAndSetData(); // No need to check because loadAndSet Checks
        },
    },

    mounted() {
        this.loadAndSetData();
    },

    methods: {
        loadAndSetData() {
            if (this.canLoadData) {
                this.loadData({ page: this.loadDataPage, perPage: this.loadDataPerPage });
            }
        },
    },
};
