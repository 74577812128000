<template>
    <div class="prediction-confirmation">
        <b-button
            @click="onClick('yes')"
            class="be-button is-success is-smaller has-text-weight-bold"
        >
            Yes
        </b-button>
        <b-button
            @click="onClick('no')"
            class="be-button is-danger is-smaller has-text-weight-bold"
        >
            No
        </b-button>
        <b-button
            @click="onClick('maybe')"
            class="be-button is-warning is-smaller has-text-weight-bold"
        >
            Maybe
        </b-button>
        <div v-if="showCommentModal" class="modal-overlay">
            <div class="modal-content">
                <h2 class="modal-title">Please leave a comment</h2>
                <b-field label="Why is this observation not correct?">
                    <b-input
                        style="margin: 10px 0;"
                        v-model="remark"
                        placeholder="Enter your comment here..."
                        type="textarea"
                        rows="3"
                    />
                </b-field>
                <div class="modal-actions">
                    <b-button @click="submitRemark" type="is-primary">
                        Submit
                    </b-button>
                    <b-button @click="cancelRemark" type="is-light">
                        Cancel
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import noticesMixin from '@/mixins/noticesMixin';
import { getApiUrl } from '@/utils/api';

export default {
    mixins: [noticesMixin],

    data() {
        return {
            showCommentModal: false,
            remark: '',
            selectedType: null,
        };
    },

    props: {
        detectionType: {
            type: String,
            default: null,
        },
        detectionId: {
            type: String,
            default: null,
        },
    },

    methods: {
        onClick(type) {
            if (type === 'no' || type === 'maybe') {
                this.selectedType = type;
                this.showCommentModal = true;
            } else {
                this.submit(1);
            }
        },
        submitRemark() {
            if (this.remark.trim()) {
                const correctness = this.selectedType === 'maybe' ? 2 : 3;

                const isSuccess = this.submit(correctness, this.remark);

                if (isSuccess) {
                    this.resetModal();
                }
            } else {
                this.displayErrorNotice({
                    message: "The comment box can't be empty!",
                    position: 'is-top-right',
                });
            }
        },
        async submit(correctness = 1, message = null) {
            const response = await axios.post(
                getApiUrl({ path: 'detection/feedback/' }),
                {
                    video_id: this.detectionType === 'video' ? this.detectionId : null,
                    image_id: this.detectionType === 'image' ? this.detectionId : null,
                    correctness,
                    user_remark: message,
                },
            );

            if (response.status !== 200) {
                this.displayErrorNotice({
                    message: 'Something went wrong. Please try again later.',
                    position: 'is-top-right',
                });
                return false;
            }

            this.displaySuccessNotice({
                message: 'Thank you for helping improve our accuracy!',
                position: 'is-top-right',
            });
            return true;
        },
        cancelRemark() {
            this.resetModal();
        },
        resetModal() {
            this.showCommentModal = false;
            this.remark = '';
            this.selectedType = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.prediction-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;

    .button + .button {
        margin-left: .75rem;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #37404C;
    color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

</style>
