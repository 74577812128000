import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

const thresholds = [
    { l: 's', r: 58, d: 'second' },
    { l: 'm', r: 89 },
    { l: 'mm', r: 58, d: 'minute' },
    { l: 'h', r: 89 },
    { l: 'hh', r: 21, d: 'hour' },
    { l: 'd', r: 35 },
    { l: 'dd', r: 25, d: 'day' },
    { l: 'M', r: 45 },
    { l: 'MM', r: 10, d: 'month' },
    { l: 'y', r: 17 },
    { l: 'yy', d: 'year' },
];

dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(relativeTime, {
    thresholds,
});
dayjs.extend(advancedFormat);
dayjs.extend(timezone);

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs;
        },
    },
    $now: {
        get() {
            return dayjs();
        },
    },
});
