<template>
    <div>
        <data-table-options />
        <b-table
            :data="formattedApiResults"
            :hoverable="true"
            :selected="lastSelectedRow"
            :class="{ 'has-cursor-loading': isRowLoading }"
        >
            <b-table-column label="Id" width="1%" v-slot="props">
                <p class="has-text-centered has-text-weight-bold">
                    <a
                        href="#"
                        class="has-text-white"
                        @click.prevent="openPopup(props.row, props.index)">{{ props.index + 1 }}</a>
                </p>
            </b-table-column>
            <b-table-column label="Image" :width="imagesPreviewEnabled ? 100 : '1%'" v-slot="props">
                <image-preview
                    v-if="imagesPreviewEnabled"
                    :row="props.row"
                    @click="openPopup($event, props.index)"
                />
                <p v-else class="has-text-centered">
                    <be-button-view-image @click="openPopup(props.row, props.index)" />
                </p>
            </b-table-column>
            <b-table-column label="Last Detection" width="100" v-slot="props">
                <span class="has-cursor-default">
                    <template v-if="fullDatesEnabled">
                        {{ props.row.image.time_created | dateTime(true) }}
                    </template>
                    <template v-else>
                        {{ props.row.image.time_created | dateTimeHuman }}
                    </template>
                </span>
            </b-table-column>
            <b-table-column label="Detections" v-slot="props">
                <be-detections-list
                    :detections="props.row.detections"
                    :rowType="props.row.rowType"
                    wrap
                />
            </b-table-column>
            <b-table-column label="Risk Score" width="80" v-slot="props">
                <span
                    :class="{
                        'low-risk': props.row.risk_level == null || props.row.risk_level <= 2,
                        'medium-risk': props.row.risk_level > 2 && props.row.risk_level <= 3,
                        'high-risk': props.row.risk_level > 3,
                    }"
                >
                    {{ props.row.risk_level || 0 }}
                </span>
            </b-table-column>
            <b-table-column label="Site" v-slot="props">
                {{ props.row.siteName || '-' }}
            </b-table-column>
            <b-table-column label="Camera" v-slot="props">
                {{ props.row.cameraName || '-' }}
            </b-table-column>
            <template slot="empty">
                <data-table-empty v-if="showZeroResults">
                    There are no results to display. Try changing your filter or search criteria.
                </data-table-empty>
            </template>
        </b-table>
        <be-pagination
            key="detections"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :count="overallCount"
        />
        <media-image-popup
            :active.sync="showCarJamInfo"
            :image-with-detail="platesAndImageAndWithDetail"
            :display-arrows="formattedApiResults && formattedApiResults.length > 1"
            :loading="isLoadingFull"
            @prev="goto('prev')"
            @next="goto('next')"
            @close="closePopup"
        />
    </div>
</template>

<script>
import detectionDetailMixin from '@/mixins/detectionDetailMixin';
import BePagination from '@/components/global/BePagination';
import BeDetectionsList from '@/components/global/BeDetectionsList';
import ImagePreview from '@/components/local/data/ImagePreview';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import dataTableMixin from '@/mixins/dataTableMixin';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import formattersMixin from '@/mixins/formattersMixin';
import DataTableOptions from '@/components/local/dataTables/DataTableOptions';
import BeButtonViewImage from '@/components/global/BeButtonViewImage';
import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';

export default {
    props: {
        type: {
            type: String,
            validator: (val) => ['', 'zones', 'ppe'].includes(val),
            default: '',
        },
    },
    components: {
        BeButtonViewImage,
        DataTableOptions,
        ImagePreview,
        BePagination,
        DataTableEmpty,
        BeDetectionsList,
    },

    mixins: [detectionDetailMixin, hasPopupMixin, widgetDataMixin, dataTableMixin, formattersMixin],

    watch: {
        isLoading(val) {
            if (val) {
                this.$emit('loading');
            } else {
                this.$emit('ready');
            }
        },
    },

    computed: {
        storePrefix() {
            const suffix = this.type ? `${this.type}/` : '';
            return `hs/explorer/detections/${suffix}`;
        },

        overallCount() {
            return this.$store.getters[`${this.storePrefix}overallCount`];
        },

        isLoading() {
            return this.$store.getters[`${this.storePrefix}isLoading`];
        },

        formattedApiResults() {
            return this.$store.getters[`${this.storePrefix}formattedApiResults`];
        },

        perPage: {
            get() {
                if (this.type) {
                    return this.$store.state.hs.explorer.detections[this.type].perPage;
                }
                return this.$store.state.hs.explorer.detections.perPage;
            },
            set(perPage) {
                this.$store.commit('dataExport/setPerPage', { data: perPage });
                return this.$store.commit(`${this.storePrefix}setPerPage`, { perPage });
            },
        },

        currentPage: {
            get() {
                if (this.type) {
                    return this.$store.state.hs.explorer.detections[this.type].currentPage;
                }
                return this.$store.state.hs.explorer.detections.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit(`${this.storePrefix}setPage`, { page });
            },
        },

        showZeroResults() {
            return !this.isLoading
                && this.overallCount === 0;
        },
    },

    methods: {
        loadData(payload) {
            return this.$store.dispatch(`${this.storePrefix}loadData`, payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.low-risk {
    color: $low-risk;
}

.medium-risk {
    color: $medium-risk;
}

.high-risk {
    color: $high-risk;
}
</style>
