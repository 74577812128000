<template>
    <div class="data-explorer">
        <overview-filters
            :can-export="true"
            :filter-confidence="true"
            :filter-risk="true"
            :is-loading="isLoading"
        />
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Health & Safety Explorer
                </template>
                <template v-slot:subheading>
                    <be-detections-heading />
                </template>
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-12">
                    <system-totals :can-load-data="canLoadTableData" />
                </div>
                <div class="column is-12">
                    <violations-explorer-table-widget
                        ref="tableWidget"
                        :can-load-data="canLoadTableData"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getApiUrl } from '@/utils/api';
import ViolationsExplorerTableWidget from '@/components/widgets/hs/dataTables/ViolationsExplorerTableWidget';
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import OverviewFilters from '@/components/global/OverviewFilters';
import SystemTotals from '@/components/widgets/hs/SystemTotals';

export default {
    name: 'DataExplorer',

    components: {
        BeDetectionsHeading,
        OverviewFilters,
        ViolationsExplorerTableWidget,
        SystemTotals,
    },

    data() {
        return {
            canLoadTableData: false,
        };
    },

    computed: {
        ...mapGetters({
            isLoadingDetections: 'hs/explorer/detections/isLoading',
            isLoadingPPEDetections: 'hs/explorer/detections/ppe/isLoading',
            isLoadingZonesDetections: 'hs/explorer/detections/zones/isLoading',
            isLoadingSummary: 'hs/summary/isLoading',
        }),

        isLoading() {
            return this.isLoadingDetections
                || this.isLoadingPPEDetections
                || this.isLoadingZonesDetections;
        },
    },

    mounted() {
        this.handleComponentMounted();
    },

    methods: {
        async handleComponentMounted() {
            await this.populateFilters();
            this.canLoadTableData = true;
        },
        async populateFilters() {
            if (!this.$route.query.tag) {
                return Promise.resolve(true);
            }

            const tagSlugs = this.$route.query.tag.split(',');

            const promises = tagSlugs.map(async (tagSlug) => {
                const response = await this.$http(getApiUrl({ path: `tags/${tagSlug}` }));
                return response.data;
            });

            try {
                const tags = await Promise.all(promises);
                this.$store.commit('dataFilters/setSelectedTags', { tags });
                this.$store.commit('dataFilters/applyFilters');
            } catch (error) {
                console.error(error);
                return Promise.reject();
            }

            return Promise.resolve(true);
        },
    },
};
</script>
