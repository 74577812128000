<template>
    <div>
        <data-table-options />
        <b-table
            :data="formattedApiResults"
            :hoverable="true"
            :selected="lastSelectedRow"
            :class="{ 'has-cursor-loading': isRowLoading }"
        >
            <b-table-column label="Id" width="1%" v-slot="props">
                <p class="has-text-centered has-text-weight-bold">
                    <a href="#" class="has-text-white" @click.prevent="openPopup(props.row, props.index)">{{ props.index + 1 }}</a>
                </p>
            </b-table-column>
            <b-table-column label="Image" :width="imagesPreviewEnabled ? 100 : '1%'" v-slot="props">
                <image-preview
                    v-if="imagesPreviewEnabled"
                    :row="props.row"
                    row-as-image
                    @click="openPopup($event, props.index)"
                />
                <p v-else class="has-text-centered">
                    <be-button-view-image @click="openPopup(props.row, props.index)" />
                </p>
            </b-table-column>
            <b-table-column label="Last Detection" width="100" v-slot="props">
                <span class="has-cursor-default">
                    <template v-if="fullDatesEnabled">
                        {{ props.row.time_created | dateTime(true) }}
                    </template>
                    <template v-else>
                        {{ props.row.time_created | dateTimeHuman }}
                    </template>
                </span>
            </b-table-column>
            <b-table-column label="Detections" v-slot="props">
                <be-detections-list
                    v-if="props.row.detections.length"
                    :detections="props.row.detections"
                    :rowType="props.row.rowType"
                    wrap
                />
                <b-tag v-else class="has-cursor-default">None</b-tag>
            </b-table-column>
            <b-table-column label="Risk Score" width="80" v-slot="props">
                <span
                    :class="{
                        'low-risk': props.row.risk_level == null || props.row.risk_level <= 2,
                        'medium-risk': props.row.risk_level > 2 && props.row.risk_level <= 3,
                        'high-risk': props.row.risk_level > 3,
                    }"
                >
                    {{ props.row.risk_level || 0 }}
                </span>
            </b-table-column>
            <b-table-column label="Site" v-slot="props">
                {{ props.row.siteName || '-' }}
            </b-table-column>
            <b-table-column label="Camera" v-slot="props">
                {{ props.row.cameraName || '-' }}
            </b-table-column>
            <template slot="empty">
                <data-table-empty v-if="showZeroResults">
                    There are no results to display. Try changing your filter or search criteria.
                </data-table-empty>
            </template>
        </b-table>
        <be-pagination
            key="all-images"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :count="overallCount"
        />
        <media-image-popup
            :active.sync="showCarJamInfo"
            :image-with-detail="platesAndImageAndWithDetail"
            :display-arrows="formattedApiResults && formattedApiResults.length > 1"
            :loading="isLoadingFull"
            @prev="goto('prev')"
            @next="goto('next')"
            @close="closePopup"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import detectionDetailMixin from '@/mixins/detectionDetailMixin';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import BePagination from '@/components/global/BePagination';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import BeDetectionsList from '@/components/global/BeDetectionsList';
import ImagePreview from '@/components/local/data/ImagePreview';
import dataTableMixin from '@/mixins/dataTableMixin';
import formattersMixin from '@/mixins/formattersMixin';
import DataTableOptions from '@/components/local/dataTables/DataTableOptions';
import BeButtonViewImage from '@/components/global/BeButtonViewImage';
import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';

export default {
    components: {
        BeButtonViewImage,
        DataTableOptions,
        BePagination,
        DataTableEmpty,
        BeDetectionsList,
        ImagePreview,
    },

    mixins: [detectionDetailMixin, hasPopupMixin, widgetDataMixin, dataTableMixin, formattersMixin],

    watch: {
        isLoading(val) {
            if (val) {
                this.$emit('loading');
            } else {
                this.$emit('ready');
            }
        },
        perPage(val) {
            if (val) {
                this.$store.commit('dataExport/setPerPage', { data: val });
            }
        },
    },

    computed: {
        ...mapGetters('hs/explorer/images', [
            'overallCount',
            'isLoading',
            'formattedApiResults',
        ]),

        perPage: {
            get() {
                return this.$store.state.hs.explorer.images.perPage;
            },
            set(perPage) {
                return this.$store.commit('hs/explorer/images/setPerPage', { perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.hs.explorer.images.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit('hs/explorer/images/setPage', { page });
            },
        },

        showZeroResults() {
            return !this.isLoading
                && this.overallCount === 0;
        },
    },

    methods: {
        ...mapActions({
            loadData: 'hs/explorer/images/loadData',
        }),
    },
};
</script>

<style lang="scss" scoped>
.low-risk {
    color: $low-risk;
}

.medium-risk {
    color: $medium-risk;
}

.high-risk {
    color: $high-risk;
}

</style>
