<template>
    <div class="media-image" :class="{ 'is-zoomed': zoomed }">
        <b-loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
        />
        <template v-if="url">
            <template v-if="isError">
                <div class="media-image__error">
                    <div class="content">
                        <p>{{ message }}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="media-image__inner" @dblclick.prevent="onZoom">
                    <div
                        ref="container"
                        class="media-image__container"
                        v-dragscroll
                        :class="{ 'is-zoomed': zoomed }"
                    >
                        <div
                            ref="scene"
                            class="media-image__scene"
                            :style="sceneStyles"
                            :class="{ 'is-zoomed': zoomed }"
                            @mousemove="onMouseMove"
                        >
                            <div
                                class="tooltip"
                                v-show="isTooltipShown"
                            >
                                {{ tooltipText }}
                            </div>
                            <img
                                ref="image"
                                :src="url"
                                class="media-image__image"
                                @load="onImageLoad"
                                @error="onImageError"
                                :style="{
                                    opacity: isReady ? 1 : 0,
                                }"
                                alt="Image"
                            />
                            <media-image-h-s-canvas
                                v-if="isHS"
                                class="media-image__canvas"
                                :is-visible="isReady"
                                :width="imageSize.width"
                                :height="imageSize.height"
                                :detections="detections"
                                :camera="camera"
                                :display-roi="displayRoi"
                                :displayHeatmap=false
                                :heatmapImage=null
                                :style="{
                                    width: `${this.imageSize.width}px`,
                                    height: `${this.imageSize.height}px`,
                                }"
                                :scale="scale"
                                :showTooltip="showTooltip"
                                :imageConfig="imageConfig"
                            />
                            <media-image-canvas
                                v-else
                                class="media-image__canvas"
                                :is-visible="isReady"
                                :width="imageSize.width"
                                :height="imageSize.height"
                                :detections="detections"
                                :image-type="imageType"
                                :row-type="rowType"
                                :display-tag="displayTag"
                                :style="{
                                    width: `${this.imageSize.width}px`,
                                    height: `${this.imageSize.height}px`,
                                }"
                                :scale="scale"
                            />
                        </div>
                    </div>
                    <div class="controls">
                        <b-button
                            :icon-right="zoomed ? 'minus' : 'plus'"
                            class="controls__control is-light"
                            @click="toggleZoom()"
                        />
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="media-image__error">
                <div class="content">
                    <p>{{ message }}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { get } from 'lodash';
import { dragscroll } from 'vue-dragscroll/src/main';
import {
    STATUS_ERROR,
    STATUS_LOADING,
    STATUS_PENDING,
    STATUS_READY,
} from '@/utils/constants';
import MediaImageCanvas from '@/components/local/data/MediaImageCanvas';
import MediaImageHSCanvas from '@/components/local/data/MediaImageHSCanvas';
import { getTansformationConfig } from '@/utils/canvas';

export default {
    components: { MediaImageHSCanvas, MediaImageCanvas },
    directives: {
        dragscroll,
    },

    props: {
        active: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        imageId: {
            type: String,
            default: '',
        },
        detections: {
            type: Object,
            default: null,
        },
        camera: {
            type: [String, Object],
            default: null,
        },
        displayRoi: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        imageType: {
            type: String,
            default: 'vehicle',
        },
        rowType: {
            type: String,
            default: '',
        },
        displayTag: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.$bus.$on('resize', this.resize);
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.resize);
        });
    },

    data() {
        return {
            imageStatus: STATUS_PENDING,
            message: 'No image provided',
            zoomed: false,
            containerSize: null,
            imageSize: {
                width: 0,
                height: 0,
            },
            scale: 1,
            currentCoordinate: { x: 0, y: 0 },
            isTooltipShown: false,
            tooltipText: '',
            image: null,
        };
    },

    computed: {
        isHS() {
            return this.rowType === 'hs';
        },

        isReady() {
            return this.imageStatus === STATUS_READY;
        },

        isError() {
            return this.imageStatus === STATUS_ERROR;
        },

        isLoading() {
            return this.imageStatus === STATUS_LOADING || this.loading;
        },

        detectionsSize() {
            const width = get(this.detections, 'img_width', 0);
            const height = get(this.detections, 'img_height', 0);

            return {
                width,
                height,
                ratio: width && width ? width / height : 1,
            };
        },

        sceneStyles() {
            if (this.zoomed || !this.detectionsSize || !this.containerSize) {
                return {};
            }

            let width;
            let height;

            if (this.detectionsSize.ratio > this.containerSize.ratio) {
                ({ width } = this.containerSize);
                height = parseInt(
                    this.containerSize.width / this.detectionsSize.ratio,
                    10,
                );
            } else {
                width = parseInt(
                    this.containerSize.height * this.detectionsSize.ratio,
                    10,
                );
                ({ height } = this.containerSize);
            }
            return {
                width: `${width}px`,
                height: `${height}px`,
            };
        },

        imageConfig() {
            return {
                image: this.image,
            };
        },
    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    this.resize();
                }
            },
            immediate: true,
        },

        url: {
            handler() {
                this.imageStatus = STATUS_LOADING;
            },
            immediate: true,
        },

        isReady(val) {
            this.resize();
            if (val) {
                this.$emit('load');
            }
        },
    },

    methods: {
        toggleZoom(force = null, center = null) {
            this.zoomed = force === null ? !this.zoomed : force;
            this.$nextTick(() => {
                this.resize();

                if (center && this.$refs.container) {
                    this.$refs.container.scrollLeft = center.x;
                    this.$refs.container.scrollTop = center.y;
                }
            });
        },

        onZoom(event) {
            if (this.zoomed) {
                return this.toggleZoom(false);
            }
            return this.toggleZoom(true, {
                x: event.offsetX,
                y: event.offsetY,
            });
        },

        resize() {
            this.$nextTick(() => {
                if (this.$refs.container) {
                    const width = this.$refs.container.offsetWidth;
                    const height = this.$refs.container.offsetHeight;
                    this.containerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }

                const { image } = this.$refs;
                if (image && image.naturalWidth && image.naturalHeight) {
                    this.imageSize = {
                        width: image.clientWidth,
                        height: image.clientHeight,
                    };
                    const { scale } = getTansformationConfig({
                        clientWidth: image.clientWidth,
                        clientHeight: image.clientHeight,
                        mediaWidth: image.naturalWidth,
                        mediaHeight: image.naturalHeight,
                    });
                    this.scale = scale;
                }
            });
        },

        onImageLoad() {
            function getCanvasFromImage(image) {
                const canvas = document.createElement('canvas');
                canvas.width = image.width * 2;
                canvas.height = image.height * 2;
                const ctx = canvas.getContext('2d');
                ctx.filter = 'blur(5px)';
                ctx.drawImage(image, 0, 0);
                return canvas;
            }

            const { image } = this.$refs;
            this.image = getCanvasFromImage(image);
            this.imageStatus = STATUS_READY;
        },

        onImageError() {
            this.imageStatus = STATUS_ERROR;
            const match = this.url.match(/Expires=(\d+)/);
            if (match) {
                if (this.$date().unix() > Number(match[1])) {
                    this.message = 'The link to this image has expired after 60 minutes of inactivity. Please refresh your browser window to reload the image.';
                    return;
                }
            }
            this.message = 'This image has been deleted as your data retention policy is set to 30 days. To review or modify this policy please contact us.';
        },
        onMouseMove(event) {
            this.currentCoordinate = {
                x: event.offsetX,
                y: event.offsetY,
            };
        },
        showTooltip(show = false, text = '') {
            this.isTooltipShown = show;
            this.tooltipText = text;

            const tooltip = this.$el.querySelector('.tooltip');
            tooltip.style.left = `${this.currentCoordinate.x - 50}px`;
            tooltip.style.top = `${this.currentCoordinate.y - 50}px`;
        },
    },
};
</script>

<style lang="scss" scoped>
.media-image {
    position: relative;
    min-width: 300px;
    min-height: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: stretch;

    &__inner {
        flex: 1;
        display: flex;
        align-items: center;
        user-select: none;
        overflow: hidden;
    }

    .grab-bing {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__container {
        flex: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        &.is-zoomed {
            display: block;
        }
    }

    &__scene {
        position: relative;
        width: 100%;

        &.is-zoomed {
            cursor: grab;
            width: 200%;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
        user-select: none;
        @include transition-default;
    }

    &__canvas {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $grey-light;
        font-size: 0.9em;
        cursor: default;
    }

    .controls {
        position: absolute;
        @include z-index(above);
        left: 0;
        right: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__control {
            @include z-index(above);
            padding: 0;
            margin: 0 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            opacity: 0.6;
            transition: opacity $speed $easing;

            &,
            &:active,
            &:focus {
                border-color: transparent;
                box-shadow: none;
                outline: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

.tooltip {
    display: block;
    position: absolute;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    z-index: 9999; // Ensure the tooltip appears above other elements
}
</style>
